export default {
  banner_title: 'Cookie consent',
  accept_all_cookies: 'Accept all cookies',
  reject_all_cookies: 'Reject all cookies',
  cookie_settings: 'Cookie settings',
  banner_content:
    "On Air's website uses cookies that help this website to function and also to track how you interact with this website. But for us to provide the best user experience, enable the specific cookies from Cookie Settings, and click on Save. For more info see our",
  banner_link_text: 'privacy policy',
  preference_dialog_title: 'Website Data Collection Preferences',
  preference_dialog_content:
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.',
  cancel_dialog_title: 'Are you sure you want to cancel?',
  cancel_dialog_content:
    'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.',
};
