'use client';

import React, { FC, ReactNode, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { userAgentDetails } from '../../utils/user-agent';

/**
 * @description Wrapper used to pass animation used by the cookies banner
 */
export const CookiesBannerAnimation: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const stickyComp = useRef(null);
  useLayoutEffect(() => {
    const timeline = gsap.timeline({
      trigger: '.cookies-banner-container'
    });
    timeline.to(stickyComp.current, {
      y: userAgentDetails().isMobile ? 0 : -408,
      bottom: userAgentDetails().isMobile ? 0 : undefined,
      duration: 1.2
    });
  }, []);
  return <div ref={stickyComp} className="cookies-banner-container" data-sentry-component="CookiesBannerAnimation" data-sentry-source-file="CookiesBannerAnimation.tsx">
      <>{children}</>
    </div>;
};