import React, { FC, ReactNode } from 'react';
import messages from './messages';
type Props = {
  bannersImage: ReactNode;
};
export const CookiesBanner: FC<Props> = ({
  bannersImage
}) => {
  const {
    banner_content,
    banner_link_text,
    banner_title
  } = messages;
  const bannerContent = <p className="m-0 cookies-banner-container__banner-content">
      {banner_content}{' '}
      <a className="cookies-banner-container__link" href="/privacy-policy/" target="_blank" rel="noopener noreferrer nofollow">
        {banner_link_text}
      </a>
      .
    </p>;
  return <div data-sentry-component="CookiesBanner" data-sentry-source-file="CookiesBanner.tsx">
      {bannersImage}
      <h3 className="body-title mt-3 mb-1">{banner_title}</h3>
      {bannerContent}
    </div>;
};