import { Store } from 'redux';

import { AuthToken } from '@/reducers/authSlice';
import { getItemFromLocalStorage } from './localStorage';
import {
  clearUserStoredData,
  getTokenFromLocalStorage,
  getTokenFromSessionStorage,
  handleLogout,
  handleValidateToken,
  REMEMBER_USER_KEY,
} from './tokenHelper';

// Load the authToken from local storage if it exists
export const initAuthPersistency = (store: Store) => {
  let token: AuthToken | undefined;
  // If the user decided he wanted to be remembered, then the token should be stored in the local storage
  // otherwise it might be stored in the session storage
  const isTokenStoredInLocalStorage =
    getItemFromLocalStorage(REMEMBER_USER_KEY);
  const storedToken = isTokenStoredInLocalStorage
    ? getTokenFromLocalStorage()
    : getTokenFromSessionStorage();

  try {
    // make sure that the token is still valid for 24 hrs
    token = handleValidateToken(storedToken, store.dispatch);
  } catch (e) {
    // Broken token found in local storage, logging out to be sure'
    handleLogout(store.dispatch);
  }

  // Subscribe and notice changes to update later
  store.subscribe(() => {
    const { auth } = store.getState();

    // If the new token in state does not equal our current token and equals undefined then clears the storage
    if (token !== auth.token && auth.token === undefined) {
      token = auth.token;

      if (!token) {
        // User logged out, removing token from local storage
        clearUserStoredData();
      }
    }
  });
};
