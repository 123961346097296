'use client';

import React, { FC, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useDispatch } from 'react-redux';
import { AuthState, AuthToken } from '@/reducers/authSlice';
import { fetchShows } from '@/reducers/eventsSlice';
import { setJwtServerSide } from '@/utils/server-functions/setJwtServerSide';
import { useAppSelector } from '../state/hooks';
type FetchTicketsOnAppLoadProviderProps = {
  children: React.ReactNode;
};
const FetchTicketsOnAppLoadProvider: FC<FetchTicketsOnAppLoadProviderProps> = ({
  children
}) => {
  const router = useRouter();
  const loginStatus: AuthState['loginStatus'] = useAppSelector(state => state.auth.loginStatus);
  const customerAccessToken: AuthToken['customerAccessToken'] | undefined = useAppSelector(state => state.auth.token?.customerAccessToken);
  const jwt: AuthToken['jwt'] | undefined = useAppSelector(({
    auth
  }) => auth?.token?.jwt);
  const expiresAt: AuthToken['expiresAt'] | undefined = useAppSelector(({
    auth
  }) => auth?.token?.expiresAt);
  const dispatch = useDispatch();
  useEffect(() => {
    if (loginStatus === 'LOGGED_IN' && customerAccessToken) {
      // TO DO - remove as any
      dispatch(fetchShows({
        auth: {
          jwt: jwt ?? ''
        }
      }) as any);
    }
  }, [loginStatus, dispatch, customerAccessToken, jwt, expiresAt]);
  useEffect(() => {
    const updateJwt = async () => {
      if (jwt) {
        await setJwtServerSide(jwt);
        router.refresh();
      }
    };
    updateJwt();
  }, [jwt]);
  return <>{children}</>;
};
export default FetchTicketsOnAppLoadProvider;